import {
  TRegisterRequest,
  authService,
  RegisterResponse,
  AuthTransport,
  HttpClient,
} from 'lib';

/**
 * Сервис логина
 *
 * Под собой использует AuthService
 */
class LoginService {
  private transport: AuthTransport;

  constructor(httpClient: HttpClient) {
    this.transport = new AuthTransport(httpClient);
  }

  checkIsLogged(token?: string): Promise<boolean> {
    return authService.check(token).catch(() => false);
  }

  /**
   * Авторизация
   */
  async login(email: string, password: string): Promise<void> {
    try {
      await authService.login(email, password);
    } catch (error) {
      throw new Error('Неправильный логин или пароль.');
    }
  }

  async register(
    data: TRegisterRequest,
  ): Promise<RegisterResponse | undefined> {
    return authService.register(data);
  }
}

export const loginService = new LoginService(new HttpClient());
