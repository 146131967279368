'use client';
import { Form, Formik, FormikProps } from 'formik';
import Link from 'next/link';
import React, { useState } from 'react';
import * as yup from 'yup';
import { FormPasswordField, FormTextField } from '../../../components/form';
import { Button, Column, Row, Spacer, Typo } from '../../../components/ui-kit';
import { carrotService, Profile } from '../../../lib';
import { EMAIL_REGEXP } from '../../../lib/validators/validators';
import { useUserInfoContext } from '../../../providers/auth-provider/user-info-provider';
import { testSelectors } from '../login-page.component';
import { loginService } from '../services';

type TLoginFormProps = {
  onSuccess?(user?: Profile | null | void): Promise<void> | void;
  customErrorMessage?(message: string): JSX.Element;
};

type TFormValues = {
  login: string;
  password: string;
};

const validationSchema = yup.object().shape({
  password: yup.string().required('Поле является обязательным'),
  login: yup
    .string()
    .email('Пожалуйста, проверьте, правильно ли указана почта')
    .matches(EMAIL_REGEXP, 'Проверьте правильность ввода email')
    .required('Пожалуйста, проверьте, правильно ли указана почта'),
});

export const LoginForm = ({
  onSuccess,
  customErrorMessage,
}: TLoginFormProps): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState('');
  const { invalidateProfile } = useUserInfoContext();

  const handleLogin = async (values: TFormValues): Promise<void> => {
    setErrorMessage('');
    await loginService
      .login(values.login, values.password)
      .then(invalidateProfile)
      .then(async (user: Profile | null | void) => {
        if (user) {
          await carrotService.restart().then(() =>
            carrotService.identifyUser({
              userId: user.id,
              email: user.email,
              phone: user.phone,
            }),
          );
        }
        return user;
      })
      .then(onSuccess)
      .catch((error: Error) => setErrorMessage(error.message));
  };
  return (
    <Formik<TFormValues>
      validationSchema={validationSchema}
      onSubmit={handleLogin}
      initialValues={{
        login: '',
        password: '',
      }}
    >
      {({ isSubmitting, isValid, errors }: FormikProps<TFormValues>) => (
        <Form placeholder="">
          <FormTextField
            invalidMessage={errors.login}
            dataAt={testSelectors.inputEmail}
            type="email"
            name="login"
            label="Email"
          />
          <Spacer size={16} />
          <FormPasswordField
            invalidMessage={errors.password}
            dataAt={testSelectors.inputPassword}
            label="Пароль"
            name="password"
          />
          <Spacer size={16} />
          <Row>
            <Column span={{ s: 'auto' }}>&nbsp;</Column>
            <Column span={{ s: 'auto' }}>
              <Typo align="right">
                <Link href="/forgot-password">Не помните пароль?</Link>
              </Typo>
            </Column>
          </Row>
          <Spacer size={32} />
          <Button
            isBlock
            size="l"
            type="submit"
            variant="primary"
            dataAt={testSelectors.submitButton}
            isDisabled={!isValid || isSubmitting}
          >
            Войти
          </Button>
          {errorMessage && (
            <>
              <Spacer size={12} />
              <Typo
                align="center"
                color="alert"
                variant="xm"
                dataAt={testSelectors.errorMessage}
              >
                {customErrorMessage?.(errorMessage) ?? errorMessage}
              </Typo>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};
