'use client';

import {
  Button,
  Column,
  Container,
  Divider,
  Header,
  Row,
  Spacer,
  Typo,
} from 'components/ui-kit';
import { useMedia } from 'hooks';
import { useRouter } from 'next/navigation';
import { useUserInfoContext } from 'providers/auth-provider/user-info-provider';
import { useEffect } from 'react';
import { LoginForm } from './component/login-form.component';
import { Breakpoint } from '../../_shared';

export const testSelectors = {
  inputEmail: 'login-email',
  inputPassword: 'login-password',
  submitButton: 'login-submit',
  errorMessage: 'error-message',
};

export const LoginPage = (): JSX.Element => {
  const router = useRouter();
  const breakpoint = useMedia();
  const { profile } = useUserInfoContext();

  const handleSuccess = (): void => {
    const params = new URLSearchParams(location.search);
    void router.push((params.get('redirectTo') as string) ?? '/account/');
  };

  useEffect(() => {
    if (profile) {
      void router.push('/account/');
    }
  }, [profile]);

  return (
    <Container>
      <Spacer size={64} />
      <Header level={1} align="center">
        Личный кабинет
      </Header>
      <Spacer size={64} />
      <Row>
        <Column span={{ s: 12, m: 6, l: 5 }}>
          <LoginForm onSuccess={handleSuccess} />
        </Column>
        <Column span={{ s: 12, m: 1, l: 2 }}>
          {breakpoint < Breakpoint.MEDIUM_SCREEN ? (
            <>
              <Spacer size={32} />
              <Divider variant="horizontal" />
              <Spacer size={32} />
            </>
          ) : (
            <Divider variant="vertical" />
          )}
        </Column>
        <Column span={{ s: 12, m: 5, l: 5 }}>
          <Typo variant="xl" weight="600">
            Ещё не&nbsp;зарегистрированы?
          </Typo>
          <Spacer size={16} />
          <Typo>
            <p>
              Создайте учётную запись, чтобы проверять заказы и&nbsp;управлять
              настройками доставки.
            </p>
          </Typo>
          <Spacer size={32} />
          <Button
            type="inner-link"
            variant="secondary"
            size="l"
            isBlock
            href="/create-account"
          >
            Регистрация
          </Button>
        </Column>
      </Row>
      <Spacer size={112} />
    </Container>
  );
};
